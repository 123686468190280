import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiRequestGet, setMyAuth } from "../data/Api";
import { useDispatch, useSelector } from "react-redux";
import { setAuth, setTestId, setTk } from "../redux/TestConfigSlice";
import { urls } from "../data/data";
import { setTestDetail } from "../redux/TestAuthenticateSlice";
import { setInstructions } from "../redux/InstructionsSlice";

const Loading = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const testIdd = useSelector((state) => state.testConfig.testId);
  const tkk = useSelector((state) => state.testConfig.tk);
  const authh = useSelector((state) => state.testConfig.auth);

  const fetchInstructions = (tk, testId) => {
    const queryParams = {
      tk: tk,
    };

    const url = `${urls.test}${testId}?${new URLSearchParams(queryParams)}`;
    fetch(url, apiRequestGet)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(setTestDetail(res?.data));
        fetch(res?.data?.testIns)
          .then((res) => {
            return res.text();
          })
          .then((res) => {
            dispatch(setInstructions(res));
            navigate("/instructions");
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const qAuth = urlParams.get("auth");
    const qTestId = urlParams.get("testId");
    const qTk = urlParams.get("tk");

    qAuth && dispatch(setAuth(qAuth));
    qTk && dispatch(setTk(qTk));
    qTestId && dispatch(setTestId(qTestId));
    qAuth && setMyAuth(qAuth);

    const timmer = setTimeout(() => {
      fetchInstructions(qTk, qTestId);
    }, 1000);

    return () => {
      clearTimeout(timmer);
    };
  }, []);
  return (
    <div className=" flex justify-center items-center h-[100vh] w-[100vw]">
      <div className="loader "></div>
    </div>
  );
};

export default Loading;
