import React from "react";
import { urls } from "../data/data";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentQuestionIndex,
  setCurrentTopicIndex,
} from "../redux/TestQuestionListSlice";
import { apiRequestGet } from "../data/Api";
import { setQuestionDetail } from "../redux/QuestionDetailSlice";
import { setLanguage } from "../redux/LanguageSlice";

const SubjectList = () => {
  const testQuestionListData = useSelector(
    (state) => state.testQuestionList?.testQues
  );
  const currentTopicIndex = useSelector(
    (state) => state.testQuestionList?.currentTopicIndex
  );
  const language = useSelector((state) => state.modal.language);
  const dispatch = useDispatch();
  const testId = useSelector((state) => state.testConfig.testId);

  const getQuestionDetails = (questId) => {
    fetch(
      `${urls.questionDetail}${questId}?${new URLSearchParams({
        testType,
        testId,
      })}`,
      apiRequestGet
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const quesLang = Object.keys(res.data?.ques).filter(
          (key) => res.data?.ques[key] !== ""
        );
        dispatch(
          setLanguage(quesLang.includes(language) ? language : quesLang?.[0])
        );
        dispatch(setQuestionDetail(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const testType = useSelector(
    (state) => state.testAuthenticate.test?.testType
  );

  return (
    <div className=" sc flex gap-5 py-4 px-5 overflow-x-scroll  h-fit bg-[#202C33] cursor-pointer select-none">
      {testQuestionListData?.tpcQues?.map((item, index) => (
        <li
          key={index}
          className={`${
            currentTopicIndex == index ? "text-white" : "text-[#8696A0]"
          } inline whitespace-nowrap  list-none`}
          onClick={() => {
            dispatch(setCurrentTopicIndex(index));
            dispatch(setCurrentQuestionIndex(0));
            getQuestionDetails(
              testQuestionListData.tpcQues?.[index].quesList?.[0].id
            );
          }}
        >
          {item?.topicName}
        </li>
      ))}
    </div>
  );
};

export default SubjectList;
